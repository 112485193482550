//@author devin
import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { jSith } from "../util/jquery-replacement";
import { BpeService } from "../modules/bpe/bpe.service";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { Doc } from "../modules/system-settings/document-management/s25.document.const";
import { AccessLevel } from "../pojo/Fls";
import { Bpe } from "../modules/bpe/BpeI";

export class DocumentService {
    @Cache({ immutable: true, targetName: "DocumentService" })
    public static getDocumentList(type?: "event" | "reservation" | "invoice" | "organization") {
        let url = "/document/list.json";
        if (type) {
            url = url + "?type_name=" + type;
        }
        return DataAccess.get(DataAccess.injectCaller(url, "DocumentService.getDocumentList")).then(function (data: {
            document: DocumentListItem[];
        }) {
            return (data && data.document) || [];
        });
    }

    @Cache({ targetName: "DocumentService" })
    public static async getDocumentTemplateList() {
        const url = "/document/templates.json";
        const injected = DataAccess.injectCaller(url, "DocumentService.getDocumentTemplateList");
        const templates = await DataAccess.get<DocumentListItem[]>(injected);
        return templates || [];
    }

    public static getDocument(documentId: number) {
        return DataAccess.get(
            DataAccess.injectCaller("/document/detail.json?itemId=" + documentId, "DocumentService.getDocument"),
        ).then(function (document: { document: Document }) {
            return document && document.document;
        });
    }

    @Cache({ targetName: "DocumentService", immutable: true })
    public static async getDocumentTemplate(documentId: number) {
        const url = `/document/template.json?itemId=${documentId}`;
        const injected = DataAccess.injectCaller(url, "DocumentService.getDocumentTemplate");
        return await DataAccess.get<Document>(injected);
    }

    @Invalidate({ serviceName: "DocumentService", methodName: "getDocumentList" })
    public static setDocument(documentId: number, type: string, document: Partial<Document>) {
        return DataAccess.put("/document/detail.json?itemId=" + (documentId || "") + "&type_name=" + type, {
            root: document,
        });
    }

    @Invalidate({ serviceName: "DocumentService", methodName: "getDocumentList" })
    public static deleteDocument(documentId: number) {
        return DataAccess.delete("/document/detail.json?itemId=" + documentId);
    }

    public static getDocumentRun(documentRunId: number) {
        return DataAccess.get("/document/run.json?itemId=" + documentRunId);
    }

    public static massageDocumentRunText(text: string) {
        text = text || "";

        //The style attribute with the “\” at the beginning and the “\” at the end prevents the HTML from being translated.
        text = text.replace(/style=\\"(.*?)\\"/gi, 'style="$1"');

        //The hex color code “#000000bf” in the style attribute generated by the various table data options like “$pro.vars.occurrencesString”
        //causes the WS report to fail.  JReport doesn’t seem to do anything with it.
        text = text.replace(/#[0-9a-z]{2}([0-9a-z]{6})/gi, "$1");

        //The unit “rem” causes the report to fail.
        text = text.replace(/([\.\-]{0,1}[0-9]+[\.0-9]*)\s*rem;/gi, "$1em;");

        let promises: any = [];
        let imageReplacements: any = [];
        text.replace(/<img (.*?)src="(.*?)"/gi, function (match, prev: string, url: string) {
            try {
                url = S25Util.toSeries25DataUrl(url);
                promises.push(
                    S25Util.toDataUri(url).then(
                        function (dataUri: string) {
                            if (dataUri) {
                                imageReplacements.push({ url: url, newUrl: dataUri });
                            } else {
                                let urlObj = new URL(url);
                                let newUrl = DataAccess.getUrl(
                                    "/proxy/request?method=GET&serviceUrl=" + encodeURIComponent(url),
                                );
                                let hostOverride = "&headerOverrides=" + encodeURIComponent("host:" + urlObj.host);
                                return S25Util.toDataUri(newUrl, "https%3A%2F%2F", "http%3A%2F%2F").then(function (
                                    dataUri: string,
                                ) {
                                    if (dataUri) {
                                        imageReplacements.push({ url: url, newUrl: dataUri });
                                    } else {
                                        newUrl += hostOverride;
                                        return S25Util.toDataUri(newUrl, "https%3A%2F%2F", "http%3A%2F%2F").then(
                                            function (dataUri: string) {
                                                dataUri && imageReplacements.push({ url: url, newUrl: dataUri });
                                            },
                                        );
                                    }
                                });
                            }
                        },
                        function (err: any) {},
                    ),
                );
            } catch (e) {}
            return match;
        });

        return S25Util.all(promises).then(function () {
            jSith.forEach(imageReplacements, function (_: number, value: any) {
                text = text.replace(value.url, value.newUrl);
            });
            return text;
        });
    }

    public static setDocumentRun(documentId: number, headerText: string, bodyText: string, footerText: string) {
        return S25Util.all({
            headerText: DocumentService.massageDocumentRunText(headerText),
            bodyText: DocumentService.massageDocumentRunText(bodyText),
            footerText: DocumentService.massageDocumentRunText(footerText),
        }).then(function (resp) {
            return DataAccess.post<{ root: { itemId: number } }>(
                "/document/run.json?itemId=" + (documentId ? documentId : ""),
                {
                    root: {
                        header_text: resp.headerText,
                        body_text: resp.bodyText,
                        footer_text: resp.footerText,
                    },
                },
            );
        });
    }

    public static async getEventDocumentRunId(documentId: number, eventId: number, variables?: Bpe.BpeVars) {
        const document = await DocumentService.getDocument(documentId);
        return DocumentService.getEventDocumentRunIdFromText(
            document.header_text,
            document.body_text,
            document.footer_text,
            documentId,
            eventId,
            variables,
        );
    }

    public static async getEventDocumentRunIdFromText(
        header: string,
        body: string,
        footer: string,
        documentId: number,
        eventId: number,
        variables?: Bpe.BpeVars,
    ) {
        let eventData: Awaited<ReturnType<typeof BpeService.getEventData>>;
        if (!variables) {
            eventData = await BpeService.getEventData(eventId);

            const orgs = S25Util.array.forceArray(eventData?.organization);
            await BpeService.getDataForOrganizations(orgs);
        }

        const [headerText, bodyText, footerText] = await Promise.all([
            BpeService.getInterpolatedString(eventData, null, null, header, true, "document", variables),
            BpeService.getInterpolatedString(eventData, null, null, body, true, "document", variables),
            BpeService.getInterpolatedString(eventData, null, null, footer, true, "document", variables),
        ]);

        const documentRun = await DocumentService.setDocumentRun(
            documentId,
            headerText.interpolatedStr,
            bodyText.interpolatedStr,
            footerText.interpolatedStr,
        );

        return documentRun.root.itemId;
    }
}

export interface DocumentListItem {
    itemId: number;
    itemName: string;
    type: Doc.ScopeId;
    accessLevel?: AccessLevel;
    reportId: number;
    description: string;
}

export interface Document {
    document_id: number;
    document_name: string;
    header_text: string;
    body_text: string;
    footer_text: string;
    prim_foreground: string;
    prim_color: string;
    sec_color: string;
    sec_foreground: string;
    document_type: Doc.ScopeId;
    report_id: number;
    file_name: string; // This is the output filename for the report
    description: string;
}
