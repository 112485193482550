//@author travis
import { S25Util } from "../util/s25-util";
import { DataAccess } from "../dataaccess/data.access";
import { jSith } from "../util/jquery-replacement";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { UserprefService } from "./userpref.service";
import { OlsService } from "./ols.service";
import { FlsService } from "./fls.service";
import { S25Const } from "../util/s25-const";
import { ContactService } from "./contact.service";
import { SequenceService } from "./sequence.service";
import { Timeout } from "../decorators/timeout.decorator";
import { PreferenceService } from "./preference.service";
import { PricingService } from "./pricing.service";
import { AccessLevels } from "../pojo/Fls";
import { Report as Reports, Report } from "../pojo/Report";
import { Flavor, MaybeArray } from "../pojo/Util";
import { Proto } from "../pojo/Proto";
import { Event as EventItem } from "../pojo/Event";
import CharBoolean = Proto.CharBoolean;
import OffsetISODateString = Proto.OffsetISODateString;
import ISODateString = Proto.ISODateString;
import NumericalString = Proto.NumericalString;
import ISODurationString = Proto.ISODurationString;
import CRC = Proto.CRC;
import ServiceStatus = Proto.ServiceStatus;
import { Telemetry } from "../decorators/telemetry.decorator";
import NumericalBoolean = Proto.NumericalBoolean;
import { S25WsNode } from "../pojo/S25WsNode";
import { WSOrganization } from "./organization.service";
import { ScheduledEmail } from "./scheduled.email.service";
import ObjectType = Report.ObjectType;
import { S25ItemI } from "../pojo/S25ItemI";
import { DropDownItem } from "../pojo/DropDownItem";
import { Requirement } from "../pojo/Requirement";
import { CustomAttributes } from "../pojo/CustomAttributes";

const EVENT_ARRAY_FIELDS = {
    event: true,
    custom_attribute: true,
    profile: true,
    todo: true,
    approval: true,
    approval_contact: true,
    role: true,
    ad_hoc_datelist: true,
    reservation: true,
    space_reservation: true,
    resource_reservation: true,
    binding_reservation: true,
    organization: true,
    bill_item: true,
    tax: true,
    event_text: true,
    content: true,
    requirement: true,
    category: true,
    messages: true,
};

//Comes from s25-event-form-constant.js
const POST_SAVE_MSG: any = {
    EV_I_MAKEDRAFT:
        "We were unable to route your event into a folder, so it was converted into a Draft state. Any locations or resources you assigned have been saved as preferences for this event. This event will not become active until it leaves Draft mode. Please consult your system administrator for more information.",
    EV_W_EXCEPT:
        "Because of an existing restriction in this event's cabinet during certain timeframes, one or all of your occurrences were not scheduled. View event details to see which occurrences were scheduled. Please consult your system administrator for more information.",
    EV_W_CHGDATE:
        "Because of date boundary restrictions in this event's cabinet, one or more of your occurrences were not scheduled. Please consult your system administrator for more information.",
    EV_W_WARNING:
        "One or more of your event occurrences has been labeled with a constraint warning, probably due to another significant event occurring at your institution at the same time. Please consult your system administrator for more information.",
    UNKNOWN_ERROR:
        "We encountered an unexpected error. See the details of this event for more information. Please consult your system administrator for more information.",
};

var eventsNeedingPricingRefresh: any[] = [];

interface itemUpdate {
    itemId: number;
    itemName: string;
    origName: string;
}

export class EventService {
    public static readonly EventArrayFields = {
        event: true,
        custom_attribute: true,
        profile: true,
        todo: true,
        approval: true,
        approval_contact: true,
        role: true,
        ad_hoc_datelist: true,
        reservation: true,
        space_reservation: true,
        resource_reservation: true,
        binding_reservation: true,
        organization: true,
        bill_item: true,
        tax: true,
        event_text: true,
        content: true,
        requirement: true,
        category: true,
        messages: true,
    };

    @Cache({ immutable: true, targetName: "EventService", expireTimeMs: 1000 })
    public static getMoreActionsData(eventId: number) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/event/more/actions/data.json?event_id=" + eventId,
                "EventService.getMoreActionsData",
            ),
        ).then(function (data) {
            return data && data.event;
        });
    }

    public static formEventCopyEditEmailPerms(
        isLoggedIn: boolean,
        fls: any,
        editOls: any,
        itemData: any,
        allowedEventStates: any,
    ) {
        var eventState = itemData && parseInt(itemData.state);
        return {
            hasEditEvent:
                isLoggedIn &&
                fls.SPEEDBOOK === "F" &&
                ["F", "C"].indexOf(editOls) > -1 &&
                (eventState === 0
                    ? ["F", "C"].indexOf(fls.EVENT_DRAFT) > -1
                    : ["F", "C"].indexOf(fls.EVENT_EVS) > -1) &&
                allowedEventStates.indexOf(eventState) > -1,
            hasCopyEvent:
                isLoggedIn &&
                fls.SPEEDBOOK === "F" &&
                editOls === "F" &&
                (eventState === 0 ? fls.EVENT_DRAFT === "F" : fls.EVENT_EVS === "F"),
            hasRelatedEvents: itemData && !!itemData.has_related,
            hasEmail: isLoggedIn && fls.EMAIL === "F",
        };
    }

    public static formEventCopyEditEmailPermsFromId(eventId: number) {
        return UserprefService.getLoggedIn().then(function (isLoggedIn: any) {
            return S25Util.all({
                itemData: isLoggedIn && EventService.getMoreActionsData(eventId),
                olsEdit: isLoggedIn && OlsService.getOls([eventId], 1, "edit"),
                allowedEventStates: isLoggedIn && UserprefService.getAllowedStates(),
                fls: FlsService.getFls(),
            }).then(function (resp) {
                return EventService.formEventCopyEditEmailPerms(
                    isLoggedIn,
                    resp.fls,
                    S25Util.propertyGet(resp.olsEdit, "access_level"),
                    resp.itemData,
                    resp.allowedEventStates,
                );
            });
        });
    }

    public static extractRoles(eventData: any) {
        var respObj: any = {
            scheduler: {},
            requestor: {},
            instructor: {},
        };

        if (eventData.role) {
            for (var i = 0; i < eventData.role.length; i++) {
                var obj = eventData.role[i];
                if (
                    obj &&
                    [S25Const.requestorRole.event, S25Const.schedulerRole.event, S25Const.instructorRole.event].indexOf(
                        parseInt(obj.role_id),
                    ) > -1 &&
                    obj.contact &&
                    obj.contact.contact_name &&
                    obj.contact.email
                ) {
                    var roleId = parseInt(obj.role_id);
                    var roleName =
                        roleId === S25Const.requestorRole.event
                            ? "requestor"
                            : roleId === S25Const.schedulerRole.event
                              ? "scheduler"
                              : roleId === S25Const.instructorRole.event
                                ? "instructor"
                                : null;
                    respObj[roleName].name = obj.contact.contact_name;
                    respObj[roleName].email = obj.contact.email;
                    respObj[roleName].contactId = parseInt(obj.contact.contact_id);
                }
            }
        }
        return respObj;
    }

    /* GETS */
    @Timeout
    @Cache({ immutable: true, targetName: "EventService" })
    public static isExpress(eventId: number) {
        return EventService.getEventMinimal(eventId).then(function (data) {
            return !!(
                data &&
                data.alien_uid &&
                data.alien_uid.startsWith &&
                data.alien_uid.toLowerCase().startsWith("express")
            );
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService" })
    public static getEventType(eventId: number): Promise<number> {
        return EventService.getEventMinimal(eventId).then(function (data) {
            return data && data.event_type_id;
        });
    }

    public static contentFilter(data: any) {
        var content = S25Util.propertyGet(data, "content");
        if (content && content.length) {
            for (var i = content.length - 1; i >= 0; i--) {
                var node = content[i];
                if (node && node.content_event_name === "(Deleted)") {
                    content.splice(i, 1);
                }
            }
        }
        return data;
    }

    @Timeout
    public static getScheduler(eventId: number) {
        return DataAccess.get(
            DataAccess.injectCaller("/scheduler/getscheduler.json?event_id=" + eventId, "EventService.getScheduler"),
        ).then(function (data) {
            var contId = S25Util.propertyGetVal(data, "cont_id");
            return contId && parseInt(contId);
        });
    }

    @Timeout
    public static getRequester(eventId: number) {
        return EventService.getEventIncludeCached(eventId, ["customers"]).then(function (eventData) {
            let requester = S25Util.propertyGetParentWithChildValue(eventData, "role_id", S25Const.requestorRole.event);
            return parseInt(S25Util.propertyGetVal(requester, "contact_id"));
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService", expireTimeMs: 3000 })
    public static isCurrentScheduler(eventId: number) {
        return ContactService.getCurrentId().then(function (contactId) {
            return EventService.getScheduler(eventId).then(function (schedulerId) {
                return schedulerId === contactId;
            });
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService" })
    public static hasRegistration(eventId: number) {
        return EventService.getEventMinimal(eventId).then(function (data) {
            return data && data.registration_url === "25Live";
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService" })
    public static getAlienUid(eventId: number) {
        return EventService.getEventMinimal(eventId).then((data) => {
            return data && S25Util.toStr(data.alien_uid);
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService" })
    public static isLynx(eventId: number) {
        return EventService.getAlienUid(eventId).then((alienUid) => {
            return !!(alienUid && alienUid.toLowerCase().startsWith("lynx"));
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService", expireTimeMs: 3000 })
    public static isCurrentRequester(eventId: number) {
        return ContactService.getCurrentId().then(function (contactId) {
            return EventService.getRequester(eventId).then(function (requesterId) {
                return requesterId === contactId;
            });
        });
    }

    @Timeout
    public static getVcal(id: number) {
        return DataAccess.post(
            DataAccess.injectCaller("/vcal.generate?event_id=" + id + "&ignore_todos", "EventService.getVcal"),
        );
    }

    @Timeout
    public static getEventsMinimal(idArray: number[]) {
        return DataAccess.post(
            DataAccess.injectCaller("/events.json?request_method=get", "EventService.getEventsMinimal"),
            S25Util.uglifyJson({
                mapxml: {
                    event_id: idArray.filter(S25Util.isDefined).join("+"),
                    scope: "minimal",
                },
            }),
        ).then(function (data) {
            data = data && S25Util.prettifyJson(data, null, { event: true });
            return (data && data.events && data.events.event) || [];
        });
    }

    @Timeout
    public static getEventsExtended(idArray: number[], includes: []) {
        return DataAccess.post(
            DataAccess.injectCaller("/events.json?request_method=get", "EventService.getEventsMinimal"),
            S25Util.uglifyJson({
                mapxml: {
                    event_id: idArray.filter(S25Util.isDefined).join("+"),
                    scope: "extended",
                    include: (includes as EventIncludeOption[]).join("+"),
                },
            }),
        ).then(function (data) {
            data = data && S25Util.prettifyJson(data, null, { event: true });
            return data && data.events && data.events.event;
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService", expireTimeMs: 1000 })
    public static getEventMinimal(id: number) {
        return DataAccess.get(
            DataAccess.injectCaller("/event.json?event_id=" + id + "&scope=minimal", "EventService.getEventMinimal"),
        ).then(function (data) {
            data = data && S25Util.prettifyJson(data);
            return data && data.events && data.events.event;
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService" })
    public static getEventIdByLocator(locator: string) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/event.json?scope=list&event_locator=" + locator,
                "EventService.getEventByLocator",
            ),
        ).then(function (data) {
            return data && data.list && data.list.item && data.list.item.id ? data.list.item.id : undefined;
        });
    }

    @Timeout
    public static getEventDates(id: number) {
        return EventService.getEventMinimal(id).then(function (data) {
            return (
                data && {
                    start_dt: data.start_date,
                    end_dt: data.end_date,
                }
            );
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService" })
    public static getEventName(id: number) {
        return DataAccess.get(
            DataAccess.injectCaller("/event.json?event_id=" + id + "&scope=list", "EventService.getEventName"),
        ).then(function (data) {
            return data && data.list && data.list.item && data.list.item.name;
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService" })
    public static getEventReports(eventId: number) {
        return DataAccess.get(
            DataAccess.injectCaller("/eventreports.json?event_id=" + eventId, "EventService.getEventReports"),
        ).then(function (data) {
            let filteredData = (data?.event_reports?.report || [])
                .filter(function (rpt: Report.SimpleObject) {
                    return ["JR", "WS", "DM"].indexOf(rpt.rpt_engine) > -1; //ANG-1402: no XSL report types    ANG-4852 added DM report engine
                })
                .sort(S25Util.shallowSort("rpt_name"));
            return EventService.moveDefaultReports(filteredData);
        });
    }

    /**
     * @param eventId
     * Returns reports that are associated with the events's type AND are payment type DM reports.
     */
    @Timeout
    public static async getEventInvoices(eventId: number) {
        let [evReports, err] = await S25Util.Maybe(EventService.getEventReports(eventId));
        if (err) return [];
        return S25Util.array
            .forceArray(evReports)
            .filter(
                (rpt: Report.SimpleObject) =>
                    rpt.object_type === ObjectType.PaymentDocument && rpt.rpt_id !== Reports.Reports.PaymentShell.id,
            );
    }

    //move "Confirmation Notice" and "Invoice" to top of returned reports
    public static moveDefaultReports(reportsArray: Report.SimpleObject[]) {
        for (let i = 0; i < reportsArray.length; i++) {
            if (reportsArray[i].rpt_use === 1 || reportsArray[i].rpt_use === 2) {
                let defaultReport = reportsArray[i];
                reportsArray.splice(i, 1);
                reportsArray.unshift(defaultReport);
            }
        }
        return reportsArray;
    }

    @Timeout
    public static getEventBindings(id: number) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/event.json?event_id=" + id + "&scope=normal&include=reservations",
                "EventService.getEventBindings",
            ),
        ).then(function (data) {
            data = data && S25Util.prettifyJson(data, null, { binding_reservation: true, profile: true });
            return data && data.events && data.events.event;
        });
    }

    //Keep this seperate for easier mocking
    public static getEventRelationshipsDao(url: string) {
        return DataAccess.get(url);
    }

    @Timeout
    public static getEventRelationships(id: number) {
        var url = DataAccess.injectCaller(
            "/event.json?event_id=" + id + "&scope=normal&include=relationships",
            "EventService.getEventRelationships",
        );
        return EventService.getEventRelationshipsDao(url)
            .then(function (data) {
                data = data && S25Util.prettifyJson(data, null, { content: true });
                return data && data.events && data.events.event;
            })
            .then(EventService.contentFilter);
    }

    @Timeout
    public static getRelatedEventIds(id: number) {
        return EventService.getEventRelationships(id).then(function (data) {
            return (
                (data &&
                    data.content &&
                    data.content.length &&
                    data.content
                        .map(function (relatedEvent: any) {
                            return parseInt(relatedEvent.content_event_id);
                        })
                        .filter(S25Util.isDefined)) ||
                []
            );
        });
    }

    @Timeout
    public static async getRelatedEventSet(eventId: number) {
        const resp = await DataAccess.get(
            DataAccess.injectCaller(`/set/get.json?event_id=${eventId}`, "EventService.getRelatedEventSet"),
        );
        return {
            itemName: resp?.set?.set_name,
            itemId: resp?.set?.set_id,
        };
    }

    public static coerceToEventDataPromise(eventIdOrData: any, includes?: any) {
        if (S25Util.isInt(eventIdOrData)) {
            return EventService.getEventInclude(eventIdOrData, includes);
        } else {
            return Promise.resolve(eventIdOrData);
        }
    }

    @Timeout
    public static getEventTasks(showRelatedEvents: boolean, eventId: number) {
        var eventIds = [eventId];
        if (showRelatedEvents) {
            return EventService.getRelatedEventIds(eventId)
                .then(function (relatedEventIds) {
                    return eventIds.concat(relatedEventIds || []);
                })
                .then(function (evIds) {
                    return EventService.getEventsInclude(
                        evIds,
                        ["workflow", "reservations", "customers"],
                        null,
                        null,
                        true,
                    );
                });
        } else {
            return EventService.getEventsInclude(eventIds, ["workflow", "reservations", "customers"], null, null, true);
        }
    }

    @Timeout
    public static getEventInclude(
        id: number,
        includes?: EventIncludeOption | EventIncludeOption[],
        noPretty?: boolean,
        stripTz?: boolean,
    ): Promise<EventData> {
        return EventService.getEventsInclude(S25Util.array.forceArray(id), includes, noPretty, true, stripTz);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService", expireTimeMs: 3000 })
    public static getEventIncludeCached(
        id: number,
        includes?: EventIncludeOption[],
        noPretty?: boolean,
        stripTz?: boolean,
    ) {
        return EventService.getEventInclude(id, includes, noPretty, stripTz);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService", expireTimeMs: 10000 })
    public static getEventIncludeCachedLong(
        id: number,
        includes?: EventIncludeOption[],
        noPretty?: boolean,
        stripTz?: boolean,
    ) {
        return EventService.getEventInclude(id, includes, noPretty, stripTz);
    }

    @Timeout
    public static getEventsIncludeDao(url: string, data: any) {
        return DataAccess.post(url, data);
    }

    public static stripTimeZones(eventData: any) {
        return S25Util.replaceDeep(eventData, {
            start_date: S25Util.date.dropTZString,
            end_date: S25Util.date.dropTZString,
            due_dt: S25Util.date.dropTZString,
            reservation_start_dt: S25Util.date.dropTZString,
            reservation_end_dt: S25Util.date.dropTZString,
            event_start_dt: S25Util.date.dropTZString,
            event_end_dt: S25Util.date.dropTZString,
            pre_event_dt: S25Util.date.dropTZString,
            post_event_dt: S25Util.date.dropTZString,
            init_start_dt: S25Util.date.dropTZString,
            init_end_dt: S25Util.date.dropTZString,
            respond_by: S25Util.date.dropTZString,
        });
    }

    @Timeout
    public static async getEventsBilling(ids: number[]) {
        const billing = await PricingService.getStandardPricingForEventIncludeRateInfo(ids);

        const defaults: any = {
            adjustment_amt: "",
            adjustment_name: "",
            adjustment_percent: "",
            bill_item_id: 0,
            bill_item_name: "",
            bill_item_type_id: 0,
            bill_item_type_name: "GRAND TOTAL",
            bill_profile_name: "",
            charge_to_id: "",
            charge_to_name: "",
            crc: "00000042",
            credit_account_number: "",
            debit_account_number: "",
            ev_dt_profile_id: -3,
            list_price: "",
            occurrences: [],
            rate_description: "",
            rate_group_id: "",
            rate_group_name: "",
            rate_id: "",
            rate_name: "",
            status: "est",
            tax: [],
            taxable_amt: 0,
            total_charge: 0,
            total_count: "",
            total_tax: 0,
            total_time: "",
        };

        const { rateGroups, rateSchedules, organizations, profiles, occurrences } = billing.expandedInfo;

        const groupNames = S25Util.fromEntries(rateGroups.map((group) => [group.rateGroup_id, group.rateGroupName]));
        const rateNames = S25Util.fromEntries(rateSchedules.map((schedule) => [schedule.rateId, schedule.rateName]));
        const orgNames = S25Util.fromEntries(organizations.map((org) => [org.organizationId, org.organizationName]));
        const profileNames = S25Util.fromEntries(profiles.map((profile) => [profile.profileId, profile.name]));
        const occNames = S25Util.fromEntries(occurrences.map((occ) => [occ.rsrvId, occ.occurrence]));

        const itemTypes: any = {
            0: "Subtotal",
            1: "Event",
            2: "Requirement",
            3: "Location",
            4: "Resource",
        };

        const events: any = { occSubtotals: {}, profileSubtotals: {}, occurrences: {} };

        for (let item of billing.data.items) {
            const billItems: any = [];
            const { totals, adjustments, lineItems, subtotals } = item.billing;

            // Grand total
            billItems.push({
                ...defaults,
                bill_item_type_name: "GRAND TOTAL",
                ev_dt_profile_id: -3,
                bill_item_id: 0,
                bill_item_type_id: 0,
                taxable_amt: totals.taxableAmount,
                total_charge: totals.grandTotal,
            });

            // Adjustments
            Array.prototype.push.apply(
                billItems,
                (adjustments || []).map((adjustment) => ({
                    ...defaults,
                    bill_item_type_name: "ADJUSTMENT",
                    bill_item_type_id: -1,
                    ev_dt_profile_id: -2,
                    adjustment_amt: adjustment.adjustmentAmt,
                    adjustment_percent: adjustment.adjustmentPercent / 100,
                    adjustment_name: adjustment.adjustmentName,
                    bill_item_id: adjustment.itemId,
                    total_charge: adjustment.totalCharge,
                    charge_to_id: adjustment.chargeToId,
                    charge_to_name: orgNames[adjustment.chargeToId],
                })),
            );

            // Subtotal
            billItems.push({
                ...defaults,
                bill_item_type_name: "Subtotals",
                ev_dt_profile_id: -1,
                total_charge: 10,
            });

            // Line items
            Array.prototype.push.apply(
                billItems,
                (lineItems || []).map((item) => ({
                    ev_dt_profile_id: item.profileId,
                    bill_profile_name: profileNames[item.profileId] || "",
                    adjustment_amt: item.adjustmentAmt,
                    adjustment_name: item.adjustmentName || "",
                    adjustment_percent: item.adjustmentPercent ? String(item.adjustmentPercent / 100) : "",
                    bill_item_id: item.itemId,
                    bill_item_name: item.itemName,
                    bill_item_type_id: item.itemType,
                    bill_item_type_name: itemTypes[item.itemType],
                    charge_to_id: item.chargeToId,
                    charge_to_name: orgNames[item.chargeToId],
                    credit_account_number: item.creditAccountNumber,
                    debit_account_number: item.debitAccountNumber,
                    occurrences: item.occurrences?.map((row) => ({ ...row, occurrence: occNames[row.rsrvId] })),
                    rate_group_id: item.rateGroupId,
                    rate_group_name: groupNames[item.rateGroupId],
                    rate_id: item.rateId,
                    rate_name: rateNames[item.rateId],
                    rateNameMap: rateNames,
                    orgNameMap: orgNames,
                    taxable_amt: item.price,
                    total_charge: item.total,
                    total_count: item.totalCount,
                    total_tax: item.taxes.reduce((sum, tax) => sum + tax.taxCharge, 0),
                    list_price: item.listPrice,
                    tax: item.taxes.map((tax) => ({
                        tax_charge: tax.taxCharge,
                        tax_id: tax.taxId,
                        tax_name: tax.taxName,
                    })),
                })),
            );

            events[item.id] = billItems;
            events.occSubtotals[item.id] = subtotals[0].accountOccurrence;
            events.profileSubtotals[item.id] = subtotals[0].accountProfile;
            events.occurrences[item.id] = occurrences;
        }

        return events;
    }

    public static async getEventsName(ids: number[]): Promise<S25ItemI[]> {
        if (!ids.length) return [];
        ids = S25Util.array.unique(ids);
        const objs: EventData[] = await EventService.getEventsMinimal(ids);
        const objIdMap = S25Util.fromEntries(objs.map((obj) => [obj.event_id, obj]));
        const idIndexMap = S25Util.fromEntries(ids.map((id, index) => [id, index]));
        return (objs ?? [])
            .map((obj) => ({ itemId: obj.event_id, itemName: obj.event_name }))
            .concat(ids.filter((id) => !objIdMap[id]).map((id) => ({ itemId: id, itemName: S25Const.private })))
            .sort((a, b) => {
                return idIndexMap[S25Util.toInt(a.itemId)] - idIndexMap[S25Util.toInt(b.itemId)];
            });
    }

    @Timeout
    public static async getEventsInclude(
        idArr: any,
        includes?: EventIncludeOption | EventIncludeOption[],
        noPretty?: boolean,
        isSingle?: boolean,
        stripTz?: boolean,
    ) {
        idArr = S25Util.array.forceArray(idArr);
        const FLS = await FlsService.getFls();
        const hasBilling = includes?.includes("billing") && FLS.EVENT_BILLING !== AccessLevels.None;
        includes = S25Util.array.forceArray(includes).filter((inc: EventIncludeOption) => inc !== "billing");
        var url = DataAccess.injectCaller("/events.json?request_method=get", "EventService.getEventsInclude");
        var data = S25Util.uglifyJson({
            mapxml: {
                event_id: idArr.filter(S25Util.isDefined).join("+"),
                scope: "extended",
                include: (includes as EventIncludeOption[]).join("+"),
            },
        });

        const billingPromise = hasBilling ? EventService.getEventsBilling(idArr) : null;

        return EventService.getEventsIncludeDao(url, data).then(function (data) {
            //do not prettify, may be used to get event details in order to edit them and immediately PUT back
            if (noPretty) return data;

            data = S25Util.prettifyJson(data, null, EVENT_ARRAY_FIELDS); //true means treat as array
            data = data && data.events && data.events.event;
            data = EventService.contentFilter(data);

            if (stripTz) data = EventService.stripTimeZones(data);

            if (hasBilling)
                return billingPromise.then((billing) => {
                    for (let event of data) {
                        event.bill_item = billing?.[event.event_id];
                        event.occSubtotals = billing?.occSubtotals[event.event_id];
                        event.profileSubtotals = billing?.profileSubtotals[event.event_id];
                        event.occurrences = billing?.occurrences[event.event_id];
                    }

                    return isSingle ? data?.[0] : data;
                });

            return isSingle ? data?.[0] : data;
        });
    }

    public static getEventsBySearchQuery(searchQuery: string, includes?: any) {
        searchQuery = searchQuery || "";
        let includeStr = (includes && includes.length && "&include=" + includes.join("+")) || "";
        let scopeStr = "scope=" + ((includeStr && "extended") || "minimal");
        return DataAccess.get("/events.json?" + scopeStr + searchQuery + includeStr).then(function (data) {
            data = data && EventService.stripTimeZones(data);
            data = data && S25Util.prettifyJson(data, null, EVENT_ARRAY_FIELDS);
            let events = data && data.events;
            return Object.assign((events && events.event) || [], {
                paginate_key: events && events.paginate_key,
                page_count: events && events.page_count,
                page_num: events && events.page_num,
                total_results: events && events.total_results,
            });
        });
    }

    @Timeout
    public static getEventAndRelated(eventId: number, includes: any) {
        let eventIds = S25Util.array.forceArray(eventId);
        return EventService.getRelatedEventIds(eventId).then(function (ids) {
            eventIds = S25Util.array.unique(eventIds.concat(ids));
            return EventService.getEventsInclude(eventIds, includes, null, null, true);
        });
    }

    @Timeout
    public static getEventState(eventId: number) {
        return EventService.getEventMinimal(eventId).then(function (data) {
            return data && (data.event_state || data.state);
        });
    }

    //@idArray: [{event_id, profile_id}, ...] <--example, set in s25-modal-add-remove-list via boundIdArrayTransform
    public static bindPerms(idArray: any[]) {
        return DataAccess.post(DataAccess.injectCaller("/boundevents/bindperms.json", "EventService.bindPerms"), {
            root: { list: idArray },
        });
    }

    public static relatePerms(idArray: any[]) {
        return DataAccess.post(DataAccess.injectCaller("/relatedevents/relateperms.json", "EventService.relatePerms"), {
            root: { event_id: idArray },
        });
    }

    public static unrelatePerms(idArray: any[]) {
        return DataAccess.post(
            DataAccess.injectCaller("/relatedevents/unrelateperms.json", "EventService.unrelatePerms"),
            { root: { event_id: idArray } },
        );
    }

    public static getSet(eventId: number) {
        return DataAccess.get(DataAccess.injectCaller("/set/get.json?event_id=" + eventId, "EventService.getSet"));
    }

    @Cache({ immutable: true, targetName: "EventService" })
    public static hasExpressPerms() {
        return DataAccess.get(
            DataAccess.injectCaller("/space/direct_schedule/check.json", "EventService.hasExpressPerms"),
        ).then(function (resp) {
            return resp && resp.direct_scheduling === "T";
        });
    }

    public static getOwner(eventId: number, includeContact?: boolean) {
        return DataAccess.get(
            DataAccess.injectCaller("/event_owner.json?event_id=" + eventId, "EventService.getOwner"),
        ).then((resp) => {
            resp = S25Util.prettifyJson(resp);
            let username =
                resp &&
                resp.object_ownership &&
                resp.object_ownership.object &&
                resp.object_ownership.object[0] &&
                resp.object_ownership.object[0].owner_username;
            if (includeContact) {
                return ContactService.getContactByUsername(username).then((user) => {
                    return user;
                });
            } else {
                return username;
            }
        });
    }

    /** DELETES **/
    @Invalidate({ serviceName: "EventService", methodName: "getNodeTypeCabinet" })
    @Invalidate({ serviceName: "EventService", methodName: "getEventById" })
    @Invalidate({ serviceName: "EventService", methodName: "getCabinetFolders" })
    public static deleteEvent(id: number) {
        return DataAccess.delete(
            DataAccess.injectCaller("/event.json?event_id=" + id, "EventService.deleteEvent"),
        ).then(
            function (resp) {
                resp = S25Util.prettifyJson(resp);
                var isSuccess =
                    (resp && resp.results && resp.results.info && resp.results.info.msg_id) === "EV_I_DELETED";
                return { error: !isSuccess, success: isSuccess };
            },
            function (error) {
                console.log(error);
                return { error: error, success: false };
            },
        );
    }

    //@idArray: [{event_id, profile_id}, ...]
    public static unbindEvents(idArray: any[]) {
        return DataAccess.post(DataAccess.injectCaller("/boundevents/unbind.json", "EventService.unbindEvents"), {
            root: { list: idArray },
        });
    }

    public static unrelateEvents(idArray: any[]) {
        return DataAccess.post(DataAccess.injectCaller("/relatedevents/unrelate.json", "EventService.unrelateEvents"), {
            root: { event_id: idArray },
        });
    }

    /** WRITES **/
    public static takeOwnership(id: number) {
        return ContactService.getCurrentUsername().then(function (currentUsername: string) {
            var payload = {
                object_ownership: {
                    object: {
                        status: "mod",
                        object_id: id,
                        object_type: 1,
                        owner_username: currentUsername,
                    },
                },
            };
            return DataAccess.put(
                DataAccess.injectCaller("/event_owner.json?event_id=" + id, "EventService.takeOwnership"),
                payload,
            ).then(function () {
                return EventService.getOwner(id, true)
                    .then((user) => {
                        return { success: user.r25user.r25_username === currentUsername, user };
                    })
                    .catch(function (resp) {
                        return resp.error;
                    });
            });
        });
    }

    public static setOwnership(eventId: number, userId: number) {
        return ContactService.getContactUsername(userId).then(function (contactUsername: string) {
            let newOwner = {
                object_ownership: {
                    object: {
                        status: "mod",
                        object_id: eventId,
                        object_type: 1,
                        owner_username: contactUsername,
                    },
                },
            };
            return DataAccess.put(
                DataAccess.injectCaller("/event_owner.json?event_id=" + eventId, "EventService.setOwnership"),
                newOwner,
            ).then(function () {
                return EventService.getOwner(eventId).then(function (username: string) {
                    return username === contactUsername;
                });
            });
        });
    }

    //@idArray: [{event_id, profile_id}, ...]
    //@contextId: {event_id, profile_id}
    @Telemetry({ category: "ManageBindings", type: "Add" })
    public static bindEvents(idArray: any, contextId: any, addExpanded?: any) {
        var json: any = { root: { list: idArray, itemId: contextId } };
        if (!S25Util.isUndefined(addExpanded)) {
            json.root.command = addExpanded; //0 or 1
        }
        return DataAccess.post(DataAccess.injectCaller("/boundevents/bind.json", "EventService.bindEvents"), json);
    }

    @Telemetry({ category: "ManageBindings", type: "ChangePrimary" })
    public static setPrimaryBoundEvent(eventId: number, profileId: number) {
        return DataAccess.put(
            DataAccess.injectCaller(
                "/boundevents/setprimary.json?event_id=" + eventId + "&profile_id=" + profileId,
                "EventService.setPrimaryBoundEvent",
            ),
        );
    }

    public static preSetEventRelationships(idArray: number[]) {
        var json = {
            root: {
                event_id: idArray,
            },
        };
        return DataAccess.post(
            DataAccess.injectCaller("/relatedevents/preset.json", "EventService.preSetEventRelationships"),
            json,
        );
    }

    public static relateEvents(idArray: number[], contextId: number, addExpanded?: any, setName?: string) {
        var json: any = { root: { event_id: idArray, itemId: contextId, itemName: setName } };
        if (!S25Util.isUndefined(addExpanded)) {
            json.root.command = addExpanded; //0 or 1
        }
        return DataAccess.post(
            DataAccess.injectCaller("/relatedevents/relate.json", "EventService.relateEvents"),
            json,
        );
    }

    public static formEventDataBody(eventData: any) {
        if (!eventData.events) {
            //if needed, wrap event data into format api expects (might be unwrapped for convenience)
            eventData = { events: { event: eventData } };
        }
        return eventData;
    }

    @Invalidate({ serviceName: "EventMicroService", methodName: "getEventDetailById" })
    @Invalidate({ serviceName: "EventService", methodName: "getNodeTypeCabinet" })
    @Invalidate({ serviceName: "EventService", methodName: "getEventById" })
    @Invalidate({ serviceName: "EventService", methodName: "getCabinetFolders" })
    @Invalidate({ serviceName: "EvdetailService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdDefnService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdPrefService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdOccurService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({
        serviceName: "SpaceService",
        methodName: "getSpacesBySearchQuery",
        patternFunc: (args) => "query_id=" + S25Util.propertyGetVal(args[1], "sp_query_id"),
        validate: (args) => S25Util.propertyGetVal(args[1], "sp_query_id"),
    })
    @Invalidate({
        serviceName: "ResourceService",
        methodName: "getResourcesBySearchQuery",
        patternFunc: (args) => "query_id=" + S25Util.propertyGetVal(args[1], "rs_query_id"),
        validate: (args) => S25Util.propertyGetVal(args[1], "rs_query_id"),
    })
    public static putEvent(id: number, eventData: any, returnDoc?: any) {
        //remove any empty new custom attributes ANG-5060 empty "new" custom attributes can slow down PUT
        eventData.custom_attribute = S25Util.array
            .forceArray(eventData.custom_attribute)
            .filter((attr: any) => attr.status !== "new" || S25Util.isDefined(attr.attribute_value));

        eventData = EventService.formEventDataBody(eventData);
        return DataAccess.put(
            DataAccess.injectCaller(
                "/rose/event.json?event_id=" +
                    id +
                    (S25Util.isDefined(returnDoc) ? "&return_doc=" + (returnDoc ? "T" : "F") : ""),
                "EventService.putEvent",
            ),
            eventData,
        ).then(function (resp) {
            const event = new Event("updateTaskCount");
            dispatchEvent(event);
            return S25Util.prettifyJson(resp, null, EVENT_ARRAY_FIELDS);
        });
    }

    public static postExpressEvent(
        name: string,
        locationId: number,
        eventTypeId: number,
        schedulerId: number,
        state: number,
        start: string,
        end: string,
        returnDoc: any,
        parentId: number,
    ) {
        return ContactService.getCurrentId().then(function (currentId) {
            var eventData = EventService.formEventDataBody({
                status: "new",
                _source: "direct_sched_event_xml.html",
                event_id: "",
                parent_id: parentId,
                event_name: name,
                alien_uid: "Express_Pro" + Date.now(),
                event_type_id: eventTypeId,
                state: state,
                primary: "T",
                profile: {
                    status: "new",
                    rec_type_id: 0,
                    init_start_dt: S25Util.date.toS25ISODateTimeStr(start),
                    init_end_dt: S25Util.date.toS25ISODateTimeStr(end),
                    reservation: [
                        {
                            status: "new",
                            reservation_id: "",
                            reservation_state: 1,
                            reservation_start_dt: S25Util.date.toS25ISODateTimeStr(start),
                            reservation_end_dt: S25Util.date.toS25ISODateTimeStr(end),
                            space_reservation: [
                                {
                                    status: "new",
                                    space_id: locationId,
                                },
                            ],
                        },
                    ],
                },
                role: [
                    {
                        status: "new",
                        role_id: -2,
                        contact: {
                            status: "new",
                            contact_id: schedulerId || currentId,
                        },
                    },

                    {
                        status: "new",
                        role_id: -1,
                        contact: {
                            status: "new",
                            contact_id: currentId,
                        },
                    },
                ],
            });

            return DataAccess.post(
                DataAccess.injectCaller(
                    "/events.json" + (S25Util.isDefined(returnDoc) ? "?return_doc=" + (returnDoc ? "T" : "F") : ""),
                    "EventService.postEvent",
                ),
                eventData,
            ).then(function (resp) {
                return S25Util.prettifyJson(resp, null, EVENT_ARRAY_FIELDS);
            });
        });
    }

    public static getNewEventProfileId() {
        return S25Util.all({
            eventId: SequenceService.getSequenceId("events"),
            profileId: SequenceService.getSequenceId("date_profile"),
        }).then(function (resp) {
            return {
                eventId: resp.eventId,
                profileId: resp.profileId,
            };
        });
    }

    public static getNewProfileId(): Promise<number> {
        return SequenceService.getSequenceId("date_profile");
    }

    public static getEventSaveNotifications(eventData: any, successMsg: string) {
        var notifications = [];
        successMsg && notifications.push(successMsg);
        var messages = S25Util.array.forceArray(S25Util.propertyGet(eventData, "messages")).filter(S25Util.isDefined);
        messages && messages.sort(S25Util.shallowSort("msg_id")); //group messages together
        jSith.forEach(messages, function (_, message) {
            if (message.msg_id && message.msg_id !== "EV_I_SAVED" && message.msg_id !== "EV_I_CREATED") {
                if (POST_SAVE_MSG[message.msg_id]) {
                    //hard-coded post save warning message
                    notifications.push(POST_SAVE_MSG[message.msg_id]);
                } else {
                    //WS dependent post save warning message
                    notifications.push(message.msg_text || message.msg);
                }
            }
        });
        return notifications;
    }

    public static updateEventCategories(eventIds: number[], catsAdd: any[], catsRemove: any[]) {
        return DataAccess.put(
            DataAccess.injectCaller("/event/categories/update.json", "EventService.updateEventCategories"),
            {
                root: {
                    events: eventIds.map(function (id) {
                        return { event_id: id };
                    }),
                    cats_add: catsAdd.map(function (id) {
                        return { cat_id: id };
                    }),
                    cats_remove: catsRemove.map(function (id) {
                        return { cat_id: id };
                    }),
                },
            },
        );
    }

    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdDefnService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdPrefService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdOccurService", methodName: "get", patternFunc: (args) => args[0] })
    public static updatePrimaryOrganization(eventIds: any, orgId: number) {
        eventIds = S25Util.array.forceArray(eventIds);
        return DataAccess.put(
            DataAccess.injectCaller(
                "/event/organization/primary/update.json?organization_id=" + orgId,
                "EventService.updatePrimaryOrganization",
            ),
            {
                root: {
                    events: eventIds.map(function (id: number) {
                        return { event_id: id };
                    }),
                },
            },
        );
    }

    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdDefnService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdPrefService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdOccurService", methodName: "get", patternFunc: (args) => args[0] })
    public static updateOrganizations(eventIds: number[], orgsAdd: any[], orgsRemove: any[]) {
        return DataAccess.put(
            DataAccess.injectCaller("/event/organization/associated/update.json", "EventService.updateOrganizations"),
            {
                root: {
                    events: eventIds.map(function (id) {
                        return { event_id: id };
                    }),
                    orgs_add: orgsAdd.map(function (id) {
                        return { org_id: id };
                    }),
                    orgs_remove: orgsRemove.map(function (id) {
                        return { org_id: id };
                    }),
                },
            },
        );
    }

    //Invalidate getEventType for eventTypeId
    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdDefnService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdPrefService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdOccurService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EventService", methodName: "getEventType", patternFunc: (args) => args[0] })
    public static updateEventType(eventIds: any, eventTypeId: number) {
        eventIds = S25Util.array.forceArray(eventIds);
        return DataAccess.put(
            DataAccess.injectCaller(
                "/event/types/update.json?event_type_id=" + eventTypeId,
                "EventService.updateEventType",
            ),
            {
                root: {
                    events: eventIds.map(function (id: number) {
                        return { event_id: id };
                    }),
                },
            },
        );
    }

    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdDefnService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdPrefService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdOccurService", methodName: "get", patternFunc: (args) => args[0] })
    public static updateEventContactRole(eventIds: any, roleId: number, contactId: number | string) {
        eventIds = S25Util.array.forceArray(eventIds);
        return DataAccess.put(
            DataAccess.injectCaller(
                "/event/role/update.json?role_id=" + roleId + "&contact_id=" + contactId,
                "EventService.updateEventContactRole",
            ),
            {
                root: {
                    events: eventIds.map(function (id: number) {
                        return { event_id: id };
                    }),
                },
            },
        );
    }

    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdDefnService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdPrefService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdOccurService", methodName: "get", patternFunc: (args) => args[0] })
    public static updateEventUnassign(
        eventIds: any,
        startDt: string,
        endDt: string,
        genPref: any,
        locationIds: number[],
    ) {
        eventIds = S25Util.array.forceArray(eventIds);

        let genPrefStr = !!genPref ? "T" : "F";
        return DataAccess.put(
            DataAccess.injectCaller(
                "/event/reservations/unassign.json?gen_pref=" + genPrefStr,
                "EventService.updateEventUnassign",
            ),
            {
                root: {
                    events: eventIds.map(function (id: number) {
                        return { event_id: id };
                    }),
                    rooms: locationIds.map(function (id) {
                        return { room_id: id };
                    }),
                    startDt: S25Util.date.toS25ISODateTimeStr(startDt),
                    endDt: S25Util.date.toS25ISODateTimeStr(endDt),
                    gen_pref: genPrefStr,
                },
            },
        );
    }

    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdDefnService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdPrefService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdOccurService", methodName: "get", patternFunc: (args) => args[0] })
    public static updateEventState(
        eventIds: any,
        newState: number,
        etags: any,
        convertPreferences?: { locations: boolean; resources: boolean },
    ) {
        eventIds = S25Util.array.forceArray(eventIds);
        etags = S25Util.array.forceArray(etags);
        return DataAccess.put(DataAccess.injectCaller("/micro/event/list.json", "EventService.updateEventState"), {
            content: {
                apiVersion: "0.1",
                etags: etags,
                data: [
                    {
                        eventIdList: eventIds,
                        context: {
                            state: newState,
                            loadDraftSpaces: convertPreferences?.locations,
                            loadDraftResources: convertPreferences?.resources,
                        },
                    },
                ],
            },
        });
    }

    /**
     *
     * @param newNames[{ itemId: int, itemName: string, origName: string }]
     * @returns {Promise}
     */
    public static updateEventName(newNames: itemUpdate[]) {
        return DataAccess.put("/event/name/update.json", {
            root: {
                type: "name",
                item: newNames.map(function (s) {
                    return { itemId: s.itemId, itemName: s.itemName };
                }),
            },
        });
    }

    public static updateEventTitle(newTitles: itemUpdate[]) {
        return DataAccess.put("/event/title/update.json", {
            root: {
                type: "title",
                item: newTitles.map(function (s) {
                    return { itemId: s.itemId, itemName: s.itemName };
                }),
            },
        });
    }

    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdDefnService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdPrefService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdOccurService", methodName: "get", patternFunc: (args) => args[0] })
    public static updateHeadcount(eventId: number, profileId: number, typeName: string, count: number) {
        return DataAccess.put(
            DataAccess.injectCaller(
                "/event/headcount/update.json?event_id=" +
                    eventId +
                    "&profile_id=" +
                    profileId +
                    "&type_name=" +
                    typeName +
                    "&count=" +
                    count,
                "EventService.updateHeadcount",
            ),
        ).then(function (resp) {
            EventService.setEventsNeedingRefresh(eventId);
            return resp;
        });
    }

    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdDefnService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdPrefService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdOccurService", methodName: "get", patternFunc: (args) => args[0] })
    public static updateDescription(eventId: number, desc: string) {
        return DataAccess.put(
            DataAccess.injectCaller(
                "/event/description/update.json?event_id=" + eventId,
                "EventService.updateDescription",
            ),
            { root: { desc: desc } },
        ).then(function (resp) {
            return resp;
        });
    }

    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    public static async updateBulkRequirements(
        eventIds: number[],
        addRequirements: EventRequirementsResp[],
        deleteRequirements?: number[],
    ) {
        const etags = await EventService.getEtags(eventIds);
        let payload = {
            content: {
                apiVersion: "0.1",
                etags: etags,
                data: [
                    {
                        eventIdList: eventIds,
                        remove: deleteRequirements
                            ? {
                                  requirements: deleteRequirements.map((reqId) => {
                                      return { requirementId: reqId };
                                  }),
                              }
                            : { requirements: [] },
                        requirements: addRequirements
                            ? addRequirements.map((req: EventRequirementsResp) => {
                                  let resp: EventRequirements = {
                                      requirementId: req.itemId,
                                  } as EventRequirements;
                                  if (req.comment || req.comment === "") resp.comments = req.comment;
                                  if ((req.quantity && req.quantity) > -1) resp.quantity = req.quantity;
                                  return resp;
                              })
                            : [],
                    },
                ],
            },
        };
        return DataAccess.put(
            DataAccess.injectCaller("/micro/event/requirement/list.json", "EventService.updateBulkRequirements"),
            payload,
        ).then((resp) => {
            return resp;
        });
    }

    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    public static updateRequirements(eventId: number, requirements: any, operation: "add" | "update" | "remove") {
        requirements = S25Util.array.forceArray(requirements);
        requirements = requirements.map((req: any) => {
            let retVal: any = {
                op: operation,
                requirementId: req.itemId,
            };
            if (S25Util.isDefined(req.quantity)) retVal.quantity = req.quantity;
            if (S25Util.isDefined(req.comment)) retVal.comments = req.comment;
            return retVal;
        });

        return DataAccess.put(
            DataAccess.injectCaller("/micro/event/" + eventId + "/requirement.json", "EventService.updateRequirements"),
            {
                content: {
                    apiVersion: "0.1",
                    data: [{ requirements: requirements }],
                },
            },
        );
    }

    public static updateFolder(queryId: number, targetFolderId: number, eventIds?: number[]) {
        if (eventIds && eventIds.length > 0) {
            return DataAccess.put("/event/folder/update.json?itemId=" + targetFolderId, {
                root: {
                    events: eventIds.map(function (id) {
                        return { event_id: id };
                    }),
                },
            });
        } else if (queryId) {
            return DataAccess.put(
                DataAccess.injectCaller(
                    "/event/folder/update.json?query_id=" + queryId + "&itemId=" + targetFolderId,
                    "EventService.updateFolder",
                ),
            ).then(function (resp) {
                return resp;
            });
        }
    }

    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    public static updateBulkEventOwner(eventIds: number[], newVal: number, mode: string) {
        let payload = {
            root: {
                events:
                    eventIds &&
                    eventIds.map((evId) => {
                        return { event_id: evId };
                    }),
            },
        };

        return DataAccess.put(
            DataAccess.injectCaller(
                "/event/owner.json?val=" + newVal + "&mode=" + mode,
                "EventService.updateBulkEventOwner",
            ),
            payload,
        ).then((resp) => {
            return resp;
        });
    }

    public static getEtags(eventIds: any) {
        return EventService.getEventsMinimal(eventIds).then(function (events) {
            var etags: any[] = [];
            jSith.forEach(events, function (_, event) {
                etags.push({
                    id: event.event_id,
                    etag: event.crc,
                });
            });
            return etags;
        });
    }

    public static async getEventsNeedingRefresh(eventIds: number[]) {
        const data = await DataAccess.post(
            DataAccess.injectCaller("/pricing/needs/refresh.json", "EventService.getEventsNeedingRefresh"),
            {
                meta: {
                    event_id: eventIds,
                },
            },
        );
        return (
            data?.event?.map(function (event: any) {
                return S25Util.copy(event, { event_id: parseInt(event.event_id) });
            }) || []
        );
    }

    public static getEventIdsNeedingRefreshLocal() {
        return [].concat(eventsNeedingPricingRefresh);
    }

    public static setEventsNeedingRefresh(eventId: number) {
        var idx = eventsNeedingPricingRefresh.indexOf(eventId);
        idx === -1 && eventsNeedingPricingRefresh.push(eventId);
        return DataAccess.put(
            DataAccess.injectCaller(
                "/pricing/needs/refresh.json?event_id=" + eventId,
                "EventService.setEventsNeedingRefresh",
            ),
        );
    }

    public static delEventsNeedingRefresh(eventId: number) {
        var idx = eventsNeedingPricingRefresh.indexOf(eventId);
        idx > -1 && eventsNeedingPricingRefresh.splice(idx, 1);
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/pricing/needs/refresh.json?event_id=" + eventId,
                "EventService.delEventsNeedingRefresh",
            ),
        );
    }

    public static cloneMultiProfileEvent(currentEventId: number, newEventId: number, contactId: number) {
        return S25Util.all({
            event: EventService.getEventInclude(
                currentEventId,
                ["customers", "reservations", "text", "categories", "attributes", "requirements", "preferences"],
                null,
                true,
            ),
            prefs: PreferenceService.getPreferences(["copy_requestor"], "S"),
        }).then(function (resp) {
            let eventData = resp.event;
            let copyRequestor =
                resp.prefs.copy_requestor.value === "" ? false : S25Util.toBool(resp.prefs.copy_requestor.value);
            let isCanceled = [98, 99].indexOf(parseInt(String(eventData.state))) > -1;
            S25Util.replaceDeep(eventData, {
                status: "new",
                event_id: "",
                profile_id: "",
                reservation_id: "",
                comment_id: "",
                rsrv_comment_id: "",
                parent_id: "",
                alien_uid: "",
                primary_reservation: "",
            });
            jSith.forEach(eventData.role, (_: any, r: any) => {
                if (r.role_id === -1 && !copyRequestor) {
                    r.contact = {
                        status: "new",
                        contact_id: contactId,
                    };
                }
            });
            jSith.forEach(eventData.profile, (_: any, p: any) => {
                //delete bound reservations w/o valid binding_reservation_id
                if (p && p.binding_reservation) {
                    jSith.forEach(
                        p.binding_reservation,
                        (idx: any, br: any) => {
                            if (br && !S25Util.toBool(br.bound_reservation_id)) {
                                p.binding_reservation.splice(idx, 1);
                            }
                        },
                        true,
                    );
                }

                //if copied event is canceled, set reservations as active
                if (isCanceled) {
                    jSith.forEach(p.reservation, (_: any, rsrv: any) => {
                        rsrv.reservation_state = 1;
                    });
                }
            });

            // ANG-5315 Filter out inactive custom attributes
            eventData.custom_attribute = S25Util.array
                .forceArray(eventData.custom_attribute)
                .filter((ca: any) => ca.attribute_defn_state !== 0);

            eventData.state = 0; //save as draft
            eventData.event_id = newEventId;
            eventData.event_name = "COPY " + eventData.event_name;
            return EventService.putEvent(newEventId, eventData);
        });
    }

    @Timeout
    public static updateEventPricing(eventId: number) {
        EventService.delEventsNeedingRefresh(eventId); //remove the entry indicating we needed a refresh (remove early here so any concurrent updates will write to the table too)
        return DataAccess.put(
            DataAccess.injectCaller(
                "/micro/event/" + eventId + "/billing.json?refresh=FORCE",
                "EventService.updateEventPricing",
            ),
            { content: "" },
        );
    }

    @Timeout
    @Invalidate({ serviceName: "EventService", methodName: "getNodeTypeCabinet" })
    @Invalidate({ serviceName: "EventService", methodName: "getEventById" })
    @Invalidate({ serviceName: "EventService", methodName: "getCabinetFolders" })
    public static deleteEvents(ids: any) {
        let payload: any = { map: { event_id: ids } };
        return DataAccess.delete(DataAccess.injectCaller("/events.json", "EventService.deleteEvents"), payload).then(
            function (resp) {
                resp = S25Util.prettifyJson(resp);
                let isSuccess =
                    (resp && resp.results && resp.results.info && resp.results.info.msg_id) === "EV_I_DELETED";
                let noPerms =
                    resp &&
                    resp.results &&
                    resp.results.noPerm &&
                    resp.results.noPerm.item &&
                    S25Util.array.forceArray(resp.results.noPerm.item).map((item: any) => {
                        return {
                            itemName: item.object_name,
                            itemId: item.object_id,
                            itemTypeId: item.object_type,
                        };
                    });
                return { error: !isSuccess, success: isSuccess, noPerms: { items: noPerms } };
            },
            function (error) {
                S25Util.showError(error);
                return { error: error, success: false };
            },
        );
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService" })
    public static getAllEventType(type?: any) {
        let url = "?all_types=T";
        type && type != "view" ? (url = "?node_type=" + type) : "";
        return DataAccess.get(DataAccess.injectCaller("/evtype.json" + url, "EventService.getAllEventType")).then(
            function (data) {
                return data && data.event_types && data.event_types.event_type;
            },
        );
    }

    @Timeout
    public static postEventType() {
        return DataAccess.post<EvTypePostResponse>(
            DataAccess.injectCaller("/evtype.json?", "EventService.postEventType"),
        ).then(function (data) {
            const eventType = data?.event_types?.event_type;
            if (!eventType) return undefined;
            return {
                ...eventType,
                reports: S25Util.array.forceArray(eventType.reports).filter((report) => report.report_id), // Filter out empty report objects
                // Convert single items into arrays
            };
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventService" })
    public static getEventTypeById(id: number) {
        return DataAccess.get(
            DataAccess.injectCaller("/evtype.json?type_id=" + id + "&scope=extended", "EventService.getEventTypeById"),
        ).then(function (data) {
            return data && data.event_types && data.event_types.event_type;
        });
    }

    @Invalidate({ serviceName: "EventService", methodName: "getEventTypeReports" })
    @Invalidate({ serviceName: "EventService", methodName: "getAllEventType" })
    @Invalidate({ serviceName: "EventService", methodName: "getEventTypeById" })
    public static putEventType(id: number, payload: any, msgId: any) {
        return DataAccess.put(DataAccess.injectCaller("/evtype.json?type_id=" + id, "EventService.putEventType"), {
            event_types: {
                event_type: payload,
            },
        }).then(
            function (resp) {
                resp = S25Util.prettifyJson(resp);
                let isSuccess = (resp && resp.results && resp.results.info && resp.results.info.msg_id) === msgId;
                return { error: !isSuccess, success: isSuccess };
            },
            function (error) {
                S25Util.showError(error);
                return { error: error, success: false };
            },
        );
    }

    @Timeout
    @Cache({ targetName: "EventService", immutable: true })
    public static async getEventTypeReports() {
        const fls = await FlsService.getFls();
        if (fls.REP_LIST === "N") return [];
        return DataAccess.get<{
            reports: {
                engine: "accl";
                pubdate: ISODateString;
                report: Report.Object[];
            };
        }>(DataAccess.injectCaller("/reports.json?report_group_id=-1", "EventService.getEventTypeReports")).then(
            function (data) {
                return S25Util.array.forceArray(data?.reports?.report);
            },
            (err) => {
                console.error(err);
                return [] as Report.Object[]; //handle any other exception
            },
        );
    }

    @Timeout
    @Cache({ targetName: "EventService", immutable: true })
    //date = YYYYMMDD
    public static getNodeTypeByEventTypeId(type: any, id?: any, date?: any) {
        let url = "?node_type=" + type;
        if (id) {
            url += "&event_type_id=" + id;
        }
        if (date) {
            url += "&end_after=" + date;
        }
        return DataAccess.get(
            DataAccess.injectCaller("/events.json" + url, "EventService.getNodeTypeByEventTypeId"),
        ).then(function (data) {
            return data && data.events && data.events.event;
        });
    }

    /////////////////// for manage cabinet and folder ///////////////
    // notes: getNodeTypeCabinet &&  getNodeTypeFolder use in s25-event-type-list.js
    @Timeout
    @Cache({ targetName: "EventService", immutable: true })
    public static getNodeTypeCabinet(date?: any) {
        let url = "?node_type=" + "C";
        date ? (url += "&end_after=" + date) : "";
        return DataAccess.get(DataAccess.injectCaller("/events.json" + url, "EventService.getNodeTypeCabinet")).then(
            function (data) {
                return data && data.events && data.events.event;
            },
        );
    }

    @Timeout
    @Cache({ targetName: "EventService", immutable: true })
    //date = YYYYMMDD
    public static getNodeTypeFolder(date?: any) {
        !date ? (date = "19991231") : "";
        let url = "?node_type=" + "F";
        url += "&end_after=" + date; //20131231
        return DataAccess.get(DataAccess.injectCaller("/events.json" + url, "EventService.getNodeTypeFolder")).then(
            function (data) {
                return data && data.events && data.events.event;
            },
        );
    }

    @Timeout
    public static postEvent() {
        return DataAccess.post(DataAccess.injectCaller("/events.json?", "EventService.postEvent")).then(
            function (data) {
                return data && data.events && data.events.event;
            },
        );
    }

    @Timeout
    @Cache({ targetName: "EventService", immutable: true })
    public static getEventById(id: any) {
        return DataAccess.get(
            DataAccess.injectCaller("/event.json?event_id=" + id + "&scope=extended", "EventService.getEventById"),
        ).then(function (data) {
            return data && data.events && data.events.event;
        });
    }

    public static postEventInherit(id: any, type?: InheritType) {
        return DataAccess.post(DataAccess.injectCaller("/event.inherit?async=T", "EventService.postEventInherit"), {
            inherit_events: {
                obj_type: type || "folder",
                event_list: id,
            },
        });
    } //25live.collegenet.com/25admin/data/belmont/run/event.inherit?async=T&caller=a-folder_manage-eInheritItem

    public static getResults(key: any) {
        return DataAccess.get(DataAccess.injectCaller("/results?request=" + key, "EventService.getResults")).then(
            function (data) {
                return data && data.results;
            },
        );
    }

    public static getEventListByEventTypeId(id: number) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/events.json?event_type_id=" + id + "&scope=list",
                "EventService.getEventListByEventTypeId",
            ),
        ).then(function (data) {
            return data && data.list && data.list.item;
        });
    }

    @Timeout
    @Cache({ targetName: "EventService", immutable: true })
    //date = YYYYMMDD
    public static getCabinetFolders(id: any) {
        let url = "?node_type=" + "F";
        url += "&parent_id=" + id;
        return DataAccess.get(DataAccess.injectCaller("/events.json" + url, "EventService.getNodeTypeFolder")).then(
            function (data) {
                return data && data.events && data.events.event;
            },
        );
    }

    /////////////////// END for manage cabinet and folder ///////////////

    public static updateBulkEditEventType(payload: any) {
        return DataAccess.put(
            DataAccess.injectCaller("/micro/eventType/list.json", "EventService.updateBulkEditEventType"),
            {
                content: {
                    apiVersion: "0.1",
                    data: [payload],
                },
            },
        );
    }

    // date range && constraints
    public static updateBulkEditContainer(payload: any) {
        return DataAccess.put(
            DataAccess.injectCaller("/micro/container/list.json", "EventService.updateBulkEditContainer"),
            {
                content: {
                    apiVersion: "0.1",
                    data: [payload],
                },
            },
        );
    }

    public static extractReservations(eventData: EventData): EventReservation[] {
        return S25Util.array.flatten(eventData.profile.map((profile) => profile.reservation));
    }

    public static extractSpaces(eventData: EventData): EventSpaceReservation[] {
        return S25Util.array.flatten(EventService.extractReservations(eventData).map((res) => res.space_reservation));
    }

    public static extractResources(eventData: EventData): EventResourceReservation[] {
        return S25Util.array.flatten(
            EventService.extractReservations(eventData).map((res) => res.resource_reservation),
        );
    }

    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdDefnService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdPrefService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdOccurService", methodName: "get", patternFunc: (args) => args[0] })
    public static microUpdate(eventIds: any, context: any, etags: any) {
        eventIds = S25Util.array.forceArray(eventIds);
        etags = S25Util.array.forceArray(etags);
        return DataAccess.put(DataAccess.injectCaller("/micro/event/list.json", "EventService.microUpdate"), {
            content: {
                apiVersion: "0.1",
                etags: etags,
                data: [{ eventIdList: eventIds, context: context }],
            },
        });
    }

    public static primeEventPut(eventId: number, eventData?: any) {
        if (!eventData) {
            eventData = {
                events: {
                    event: {
                        status: "new",
                        event_id: eventId,
                        start_date: S25Util.date.currentDateTime(),
                        end_date: S25Util.date.currentDateTime(),
                        event_name: "Presave: " + eventId,
                        state: -1, // pre-save state
                        event_type_id: -1, // unknown type
                    },
                },
            };
        } else if (!eventData.events) {
            eventData = { events: { event: eventData } };
        }
        DataAccess.put("/event.json?prime", eventData);
    }
}

export type EventData = S25WsNode & {
    alien_uid: string;
    cabinet_id: number;
    cabinet_name: string;
    creation_dt: OffsetISODateString;
    end_date: ISODateString;
    event_id: number;
    event_locator: Flavor<string, "event_locator">;
    event_name: string;
    event_priority: number;
    event_title: string;
    event_type_id: number;
    event_type_name: string;
    favorite: CharBoolean;
    id?: string;
    last_mod_dt: OffsetISODateString;
    last_mod_user: string;
    node_type: "E" | "F" | "C";
    node_type_name: "event" | "folder" | "cabinet";
    parent_id: number;
    registration_url: string;
    start_date: ISODateString;
    state: EventItem.State.Id;
    state_name: EventItem.State.Name;
    version_number: number | NumericalString;
    profile: EventProfile[];
    organization?: MaybeArray<
        S25WsNode & {
            address?: WSOrganization["address"];
            contact?: WSOrganization["contact"];
            organization_id: number;
            organization_inherited: NumericalBoolean;
            organization_name: string;
            organization_title: string;
            primary: CharBoolean;
            organization_details: S25WsNode & {
                account_number: string;
                abbreviation: string;
                organization_rating: string;
                organization_rating_id?: number;
            };
        }
    >;
    scheduledEmails?: ScheduledEmail[];
    requirement?: MaybeArray<{
        req_comment: string;
        req_defn_state: NumericalBoolean;
        req_sort_order: number;
        requirement_count: number;
        requirement_id: number;
        requirement_inherit: NumericalBoolean;
        requirement_name: string;
        requirement_type: Requirement.Type;
        requirement_type_name: string;
    }>;
    role?: MaybeArray<{
        role_id: number;
        role_name: string;
        role_sort_order: number;
        contact: {
            contact_id: number;
            contact_first_name: string;
            contact_last_name: string;
            contact_middle_name: string;
            contact_name: string;
            email: string;
            fax: string;
            formatted_address: string;
            phone: string;
        };
    }>;
    custom_attribute?: MaybeArray<{
        attribute_defn_state: NumericalBoolean;
        attribute_id: number;
        attribute_name: string;
        attribute_sort_order: number;
        attribute_type: CustomAttributes.Type;
        attribute_type_name: string;
        attribute_value: string;
    }>;
    approval?: EventItem.Workflow.Task[];
    // The below are not yet typed
    bill_items?: any[];
    events?: any;
    todo?: any;
};

export type EventProfile = {
    crc?: CRC;
    expected_count?: NumericalString;
    id?: string;
    init_end_dt: OffsetISODateString;
    init_start_dt: OffsetISODateString;
    post_event: ISODurationString;
    pre_event: ISODurationString;
    prof_use: number;
    prof_use_name: string;
    profile_code: string;
    profile_comments: string;
    profile_description: string;
    profile_id: number;
    profile_name: string;
    rec_type_id: number;
    rec_type_name: string;
    registered_count?: NumericalString;
    status?: string;
    setup_profile?: EventSetupProfile;
    takedown_profile?: EventTakedownProfile;
    ad_hoc_datelist?: EventAdHocDate[];
    reservation: EventReservation[];
    binding_reservation?: EventBindingReservation[];
};

type EventBindingReservation = {
    bound_event_id: number;
    bound_name: string;
    bound_reservation_id: number; // Profile ID
    primary_reservation: number; // Profile ID
};

type EventSetupProfile = {
    crc: CRC;
    setup_profile_id: number;
    setup_tm: ISODurationString;
    status: ServiceStatus;
};

type EventTakedownProfile = {
    crc: CRC;
    takedown_profile_id: number;
    tdown_tm: ISODurationString;
    status: ServiceStatus;
};

type EventAdHocDate = {
    ad_hoc_start_dt: ISODateString;
    crc: CRC;
    status: ServiceStatus;
};

export type EventReservation = {
    attendee_count?: NumericalString;
    crc?: CRC;
    event_end_dt: OffsetISODateString;
    event_start_dt: OffsetISODateString;
    post_event_dt: OffsetISODateString;
    pre_event_dt: OffsetISODateString;
    reservation_end_dt: OffsetISODateString;
    reservation_id: number;
    reservation_start_dt: OffsetISODateString;
    reservation_state: EventItem.Reservation.States;
    rsrv_comment_id?: number;
    rsrv_comments?: string;
    status?: ServiceStatus;
    space_reservation?: EventSpaceReservation[];
    resource_reservation?: EventResourceReservation[];
};

type EventSpaceReservation = {
    attendance: number;
    crc?: CRC;
    default_layout_capacity: number;
    layout_id?: number;
    layout_name: string;
    rating: number;
    selected_layout_capacity: number;
    share: CharBoolean;
    space: {
        space_name: string;
        max_capacity: number;
        formal_name: string;
        partition_name: string;
    };
    space_id: number;
    space_instructions: string;
    status?: ServiceStatus;
};

type EventResourceReservation = {
    crc: CRC;
    quantity: number;
    resource: { resource_name: string };
    resource_id: number;
    resource_instructions: string;
    status: ServiceStatus;
};

export type EventIncludeOption =
    | "customers"
    | "reservations"
    | "billing"
    | "text"
    | "categories"
    | "history"
    | "attributes"
    | "workflow"
    | "workflow_history"
    | "requirements"
    | "preferences"
    | "profile"
    | "relationships";

type EvTypePostResponse = {
    event_types: {
        engine: "accl";
        pubdate: ISODateString;
        event_type: {
            class_name: "";
            defn_state: "";
            favorite: "";
            last_mod_dt: "";
            last_mod_user: "";
            node_type: "";
            node_type_name: "";
            parent_id: "";
            sort_order: "";
            status: ServiceStatus;
            tag_value: "";
            type_id: number;
            type_name: "";
            categories: {
                category_id: "";
                category_name: "";
                status: ServiceStatus;
            };
            contact_role: {
                role_id: "";
                role_name: "";
                status: ServiceStatus;
            };
            custom_attributes: {
                attribute_id: "";
                attribute_name: "";
                attribute_sort_order: "";
                status: ServiceStatus;
            };
            inheritance_rules: {
                inherit_item: "";
                inherit_parent: CharBoolean;
                inherit_rule: "";
                local_edit: CharBoolean;
                override: CharBoolean;
                show_data: CharBoolean;
                vcal: "";
                status: ServiceStatus;
            };
            notifications: {
                approval_from: "";
                respond_within: "";
                status: ServiceStatus;
            };
            reports: {
                report_engine: "";
                report_id: "";
                report_name: "";
                report_use: "";
                status: ServiceStatus;
            };
            requirements: {
                requirement: "";
                requirement_id: "";
                requirement_type: "";
                requirement_type_name: "";
                status: ServiceStatus;
            };
            routing_rules: {
                routing_rule_id: "";
                routing_rule_name: "";
                status: ServiceStatus;
            };
        };
    };
};

export type InheritType = "event" | "folder";

export type Etags = {
    id?: number;
    etag?: string;
};

export type EventRequirements = {
    requirementId?: number;
    quantity?: number;
    comments?: string;
};
export interface EventRequirementsResp extends DropDownItem {
    requirementId?: number;
    quantity?: number;
    comment?: string;
}
